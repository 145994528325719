<template>
  <div v-if="client" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <h1 v-if="client.number" class="page-title">
      {{ $t('client') }} -
      <span class="fw-semi-bold">{{ $t('edit') }}</span>
    </h1>
    <h1 v-else class="page-title">
      {{ $t('client') }} -
      <span class="fw-semi-bold">{{ $t('create') }}</span>
    </h1>

    <Widget v-if="client">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Basisdaten</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('name')"
            label-for="name"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="client.name" type="text" :placeholder="$t('name')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('street')"
            label-for="street"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="client.street" type="text" :placeholder="$t('street')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('zip')"
            label-for="zip"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="client.zip" type="text" :placeholder="$t('zip')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('city')"
            label-for="city"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="client.city" type="text" :placeholder="$t('city')" />
          </b-form-group>
          <b-form-group
            horizontal
            :label="$t('description')"
            label-for="description"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-textarea
              id="description"
              v-model="client.description"
              placeholder="..."
              rows="4"
              max-rows="20"
            ></b-form-textarea>
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>{{ $t('electricians') }}</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('electricians')"
            label-for="electricians"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('electricians') + ' ' + $t('select')"
              v-model="client.electricians"
              :options="getElectricians"
              :close-on-select="false"
              :clear-on-select="false"
              :multiple="true"
              :taggable="true"
              :show-labels="true"
              :allow-empty="true"
              track-by="id"
              label="name"
              :selectLabel="$t('select')"
              :selectedLabel="$t('selected')"
              :deselectLabel="$t('remove_selection')"
            >
            </multiselect>
          </b-form-group>

          <!-- <legend>
            <strong>{{ $t('employers') }}</strong>
          </legend> -->

          <!-- <b-form-group
            horizontal
            :label="$t('employers')"
            label-for="employers"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('employers') + ' ' + $t('select')"
              v-model="client.employers"
              :options="getEmployers"
              :close-on-select="false"
              :clear-on-select="false"
              :multiple="true"
              :taggable="true"
              :show-labels="true"
              :allow-empty="true"
              track-by="id"
              label="name"
              :selectLabel="$t('select')"
              :selectedLabel="$t('selected')"
              :deselectLabel="$t('remove_selection')"
            >
            </multiselect>
          </b-form-group> -->
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>{{ $t('email') }} {{ $t('credentials') }} (SMTP)</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('mailFrom')"
            label-for="mailFrom"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="client.mailFrom" type="email" :placeholder="$t('mailFrom')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('smtpHost')"
            label-for="smtpHost"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="client.smtpHost" type="text" :placeholder="$t('smtpHost')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('smtpPort')"
            label-for="smtpPort"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="client.smtpPort" type="text" :placeholder="$t('smtpPort')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('smtpUser')"
            label-for="smtpUser"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="client.smtpUser" type="text" :placeholder="$t('smtpUser')" />
          </b-form-group>

          <b-form-group
            horizontal
            label="Passwort"
            label-for="password"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-input-group id="password">
              <b-input-group-text slot="prepend">
                <i class="fa fa-lock" />
              </b-input-group-text>
              <b-input-group-text slot="append">
                <button class="btn btn-sm p-0" @click.prevent="togglePassword">
                  <i class="fa fa-eye" />
                </button>
              </b-input-group-text>
              <b-form-input
                v-model="client.smtpPass"
                :type="passwordType"
                id="password"
                autocomplete="new-password"
              />
            </b-input-group>
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('mailBccTo')"
            label-for="mailBccTo"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="client.mailBccTo" type="email" :placeholder="$t('mailBccTo')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('mailReplyTo')"
            label-for="mailReplyTo"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input
              v-model="client.mailReplyTo"
              type="email"
              :placeholder="$t('mailReplyTo')"
            />
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>{{ $t('emailSignature') }}</strong>
          </legend>

          <editor
            v-model="client.emailSignature"
            :init="{
              plugins: 'code link lists table',
              toolbar: 'bold italic | bullist numlist | link | fontsize forecolor fontfamily',
              skin: false,
              content_css: false,
              content_style: 'body { font-family: Calibri; font-size: 10pt}',
              font_family_formats:
                'Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Calibri=calibri; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats',
            }"
          />
          <!-- <pre><code>{{client.emailSignature}}</code></pre> -->
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showDeleteButton="buttons.showDeleteButton"
            :model="client"
            modelEditRoute="ClientEditPage"
            modelListRoute="ClientsPage"
            modelRouteParamName="clientNumber"
            :updateCallback="updateClient"
            :createCallback="createClient"
            :deleteCallback="deleteClient"
            :fetchCallback="fetchClients"
          />
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import ButtonBar from '@/components/ButtonBar.vue';

// import CKEditor from '@ckeditor/ckeditor5-vue2';
// import InlineEditor from '@ckeditor/ckeditor5-build-inline';
import Editor from '@tinymce/tinymce-vue';
/* Import TinyMCE */
import tinymce from 'tinymce';

/* Default icons are required. After that, import custom icons if applicable */
import 'tinymce/icons/default';

/* Required TinyMCE components */
import 'tinymce/themes/silver';
import 'tinymce/models/dom';

/* Import a skin (can be a custom skin instead of the default) */
// import 'tinymce/skins/ui/oxide/skin.css';

/* Import plugins */
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/code';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/table';

/* Import premium plugins */
/* NOTE: Download separately and add these to /src/plugins */
/* import './plugins/checklist/plugin'; */
/* import './plugins/powerpaste/plugin'; */
/* import './plugins/powerpaste/js/wordimport'; */

/* content UI CSS is required */
// import contentUiSkinCss from 'tinymce/skins/ui/oxide/content.css';

/* The default content CSS can be changed or replaced with appropriate CSS for the editor content. */
// import contentCss from 'tinymce/skins/content/default/content.css';

export default {
  name: 'ClientEditPage',
  components: {
    Widget,
    Breadcrumbs,
    editor: Editor,
    Multiselect,
    ButtonBar,
    // ckeditor: CKEditor.component,
    // Editor,
  },
  props: {
    clientNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: null,
      buttonsDisabled: false,
      passwordType: 'password',
      // editor: InlineEditor,
      // editorConfig: {},
    };
  },
  computed: {
    ...mapGetters(['getClient', 'getClientByNumber', 'getElectricians', 'getEmployers']),
    buttons() {
      return {
        showDeleteButton: this.client && this.client.number !== '_new' ? true : false,
      };
    },
    client() {
      return this.getClientByNumber(this.number);
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('client'), route: { name: 'ClientsPage' } },
        { name: this.client.number !== '_new' ? this.$t('edit') : this.$t('new') },
      ];
    },
  },
  methods: {
    ...mapActions([
      'initClient',
      'fetchClients',
      'fetchClientByNumber',
      'updateClient',
      'createClient',
      'deleteClient',
      'addEmptyClientContact',
      'removeClientContact',
      'fetchElectricians',
      'fetchEmployers',
    ]),

    onEditorReady(event) {},
    togglePassword() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    },

    isDisabled(e) {
      e.preventDefault();
    },
  },
  async mounted() {
    if (!this.client && this.clientNumber) {
      this.number = this.clientNumber;
      await this.fetchClientByNumber(this.number);
    } else {
      this.initClient();
      this.number = '_new';
    }
    await this.fetchElectricians();
    await this.fetchEmployers();
  },
};
</script>

<style scoped lang="scss">
.clientContacts {
  text-align: right;
}
.trash-contact {
  cursor: pointer;
}
legend {
  position: relative;
}
.button-right {
  position: absolute;
  display: inline-block;
  right: 0;
  top: 2px;
}
</style>
